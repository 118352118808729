import React from 'react';


//import './404.scss';

/**
 * 404 Page.
 */
const NotFound = p => {


  return(

    <div className="404">
  
    </div>

  )

};

export default NotFound;
